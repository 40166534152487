/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// Correct place for CSS. Layout will just unmount and remount it and could cause issues.
// and the correct place is within gatsby-browser.js
require('./src/styles/index.scss');
require('./src/styles/required.scss');

const React = require('react');
// Need to use .default in this case as it's not export const Layout but export default Layout
const Layout = require('./src/components/Layout').default;

// Logs when the client route changes
exports.onRouteUpdate = ({ location, prevLocation }) => {
  //   console.log('new pathname', location.pathname);
  //   console.log('old pathname', prevLocation ? prevLocation.pathname : null);

  // For Anchor URLs
  if (location.hash) {
    setTimeout(() => {
      let element = document.getElementById(`${location.hash}`);
      if (element) {
        // Gets the Header
        let header = document.querySelector('header');
        // Then gets the height and uses this as the offset.

        const y =
          element.getBoundingClientRect().top +
          window.pageYOffset -
          header.clientHeight;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }, 0);
  }

  if (prevLocation?.search) {
    const params = new URLSearchParams(prevLocation.search);
    const msxt = params.get('msxt');
    const currentSearch = window.location.search;

    if (msxt && !(currentSearch && currentSearch.includes('msxt='))) {
      if (currentSearch) {
        window.history.replaceState(
          window.history.state,
          '',
          window.location.search + '&msxt=' + msxt,
        );
      } else {
        window.history.replaceState(
          window.history.state,
          '',
          window.location.search + '?msxt=' + msxt,
        );
      }
    }
  }
};

exports.onPreRouteUpdate = ({ location, prevLocation }) => {
  if (prevLocation?.search) {
    const params = new URLSearchParams(prevLocation.search);
    const msxt = params.get('msxt');
    const currentSearch = location.search;

    if (msxt && !(currentSearch && currentSearch.includes('msxt='))) {
      if (location.search) {
        location.search = '&msxt=' + msxt;
      } else {
        location.search = '?msxt=' + msxt;
      }
    }
  }
};

// Wraps every page in a Layout component
exports.wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

exports.shouldUpdateScroll = () => true;

// Recommendation from the Gatsby Site
// https://www.gatsbyjs.com/docs/how-to/performance/add-offline-support-with-a-service-worker/
exports.onServiceWorkerUpdateReady = () => {
  console.log('Website Cache out of date. Reloading');
  window.location.reload();
};
