import React, { useState } from 'react';
import LitImage from '../images/LitImage';
import { getContractDetails, getRoundedNumber } from '../../data/Products';
import { graphql, useStaticQuery } from 'gatsby';
import LitStructuredText from '../generic/LitStructuredText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
const ProductTile = ({
  plan,
  onButtonClick,
  imageData,
  isSalesJourney,
  chosenProduct,
  isProductClick,
  promotion,
  isProductPagePath,
  productData,
}) => {
  const [moreInfo, setMoreInfo] = useState();
  const iconData = iconQuery();
  const highlightedProduct = chosenProduct === plan.productCode;
  const defaultPlanfeatures = [
    { icon: iconData.moneyProduct, text: `No mid-contract price hikes` },
    { icon: iconData.supportProduct, text: `No setup fee` },
    { icon: iconData.contractProduct, text: `8am-8pm support` },
  ];
  const applicablePromotion = promotion?.products[plan.productCode];
  const planPriceRounded = getRoundedNumber(plan.price);
  const contractDetails = getContractDetails(plan.contract);
  const contractLength = contractDetails?.length ?? 12;
  const showPricing = isSalesJourney || isProductClick;

  const roundedPrice =
    applicablePromotion?.promoPackagePrice &&
    getRoundedNumber(applicablePromotion?.promoPackagePrice);
  const promoPeriod = applicablePromotion?.promoPeriod ?? 0;
  const discountPrice = roundedPrice ?? planPriceRounded;
  const promotionRoundedPrice = applicablePromotion?.promoPeriodPackagePrice
    ? getRoundedNumber(applicablePromotion?.promoPeriodPackagePrice)
    : discountPrice;
  const currentProductData = productData.filter(
    (data) => plan.speed.includes(data.displaySpeed) && data,
  );

  const {
    productFeatures,
    shortDescription,
    uploadSpeed,
    downloadSpeed,
    longDescription,
  } = currentProductData[0];

  return (
    <div
      className={`Black-hover-highlight ${
        highlightedProduct ? 'highlighted-product' : 'unhighlighted-product'
      } ${moreInfo && 'open-product'}`}
    >
      {' '}
      {promoPeriod && (
        <div
          className={`card-promo-banner `}
          onClick={(e) => onButtonClick(e, plan.productCode)}
        >
          <p>
            Buy now for just £{promotionRoundedPrice}{' '}
            {promoPeriod > 1 && 'a month'} for the first{' '}
            {promoPeriod > 1 && promoPeriod} month{promoPeriod > 1 ? 's' : ''}
          </p>
        </div>
      )}
      <div
        className="product-card-inner"
        onClick={(e) => onButtonClick(e, plan.productCode)}
        style={{
          borderTop: `${!promoPeriod && 'none'}`,
        }}
      >
        <div className={`card-header`}>
          <div className="card-header-product-description">
            <div className="card-header-product">
              <h3>{plan.name}</h3>
            </div>
            <div className="card-header-short-description">
              <p>{shortDescription}</p>
            </div>
          </div>
          <div className="card-header-speeds-price">
            <div className="speeds">
              <LitStructuredText data={uploadSpeed} />
              <LitStructuredText data={downloadSpeed} />
            </div>
            <div className="price">
              <p>£{discountPrice}pm</p>
            </div>
          </div>
        </div>
        {moreInfo && (
          <div className="card-body">
            <div className="card-body-long-description">
              <LitStructuredText data={longDescription} />
            </div>
            {productFeatures.map(({ text }, index) => {
              return (
                <div className="card-body-features" key={index}>
                  <LitStructuredText data={text} />
                </div>
              );
            })}
          </div>
        )}

        <div className="product-card-button">
          <button
            type="button"
            onClick={(e) => onButtonClick(e, plan.productCode)}
            id={plan.productCode}
            aria-label={'Add to basket'}
            title={'Add to basket'}
          >
            <span
              className={`${
                moreInfo && highlightedProduct && 'open-highlighted-button'
              }`}
            ></span>
          </button>
        </div>
      </div>
      <button className="card-more-info" onClick={() => setMoreInfo(!moreInfo)}>
        {moreInfo ? 'Less Info' : 'More info'}
        <FontAwesomeIcon icon={faCaretRight} />
      </button>
    </div>
  );
};

export default ProductTile;

const iconQuery = () => {
  return useStaticQuery(graphql`
    {
      bulletPointIcon: file(
        relativePath: { eq: "product-images/bulletPointIcon.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            quality: 75
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
            width: 320
          )
        }
      }
      supportProduct: file(
        relativePath: { eq: "product-images/supportProduct.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            quality: 75
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
            width: 320
          )
        }
      }
      contractProduct: file(
        relativePath: { eq: "product-images/contractProduct.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            quality: 75
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
            width: 320
          )
        }
      }
      moneyProduct: file(
        relativePath: { eq: "product-images/moneyProduct.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            quality: 75
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
            width: 320
          )
        }
      }
      speedIcon1000: file(
        relativePath: { eq: "product-images/speedProduct1000.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            quality: 75
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
            width: 320
          )
        }
      }
      speedIcon500: file(
        relativePath: { eq: "product-images/speedProduct500.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            quality: 75
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
            width: 320
          )
        }
      }
      speedIcon100: file(
        relativePath: { eq: "product-images/speedProduct100.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            quality: 75
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
            width: 320
          )
        }
      }
      wifiIcon: file(relativePath: { eq: "product-images/wifiProduct.png" }) {
        childImageSharp {
          gatsbyImageData(
            quality: 75
            placeholder: BLURRED
            layout: CONSTRAINED
            formats: [AUTO, WEBP, AVIF]
            width: 320
          )
        }
      }
    }
  `);
};
