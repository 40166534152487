import React, { createContext, useContext } from 'react';

const ActivityContext = createContext({
  userIdle: null,
  setUserIdle: () => {},
  contactSubmitted: null,
  setContactSubmitted: () => {},
});

export default ActivityContext;
