import React, { Component, useCallback, useEffect, useState } from 'react';
import Script, { ToggleElement } from './Script';
import { withPrefix } from 'gatsby';

const elementsToRemove = [''];

const LiveChat = ({ locations, enabled }) => {
  const [loaded, setLoaded] = useState(false);
  const salesforceMessenger = process.env.GATSBY_FEATURE_SALESFORCE_MESSENGER;
  !salesforceMessenger && ToggleElement('.embeddedServiceHelpButton', enabled);

  const salesforceMessengerSource =
    'https://litfibre--uat.sandbox.my.site.com/ESWLitFibreChatinappw1732808963452/assets/js/bootstrap.min.js';

  const initEmbeddedMessagingDev = useCallback(() => {
    try {
      if (salesforceMessenger) {
        embeddedservice_bootstrap.settings.language = 'en_US';
        embeddedservice_bootstrap.init(
          '00D8E000000IE5R',
          'Lit_Fibre_Chat_inapp_website',
          'https://litfibre--uat.sandbox.my.site.com/ESWLitFibreChatinappw1732808963452',
          {
            scrt2URL: 'https://litfibre--uat.sandbox.my.salesforce-scrt.com',
          },
        );
      }
      setLoaded(true);
    } catch (err) {
      console.error('Error loading Embedded Messaging: ', err);
    }
  }, [loaded, setLoaded]);

  // Live version of the above goes here

  if (typeof window === 'undefined' || !window.document || !enabled) {
    return null;
  }

  const isDev =
    process.env.NODE_ENV === 'development' ||
    process.env.GATSBY_ENVIRONMENT === 'development';

  return (
    <>
      {isDev ? (
        <Script
          source={
            salesforceMessenger
              ? salesforceMessengerSource
              : withPrefix('sf.livechat-dev.js')
          }
          appendTo={document.body}
          callback={salesforceMessenger && initEmbeddedMessagingDev}
        />
      ) : (
        <Script
          source={withPrefix('sf.livechat.js')}
          appendTo={document.body}
        />
      )}
    </>
  );
};

export default LiveChat;
